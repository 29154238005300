import { useEffect } from 'react';
import {Outlet, useLocation} from "react-router-dom";
import { sticky, goToTop } from './components/animation';
import Header from './components/Header';
import Footer from './components/Footer';
import Divider from "./components/divider";
import FooterButton from "./components/FooterButton";
import chua from "./images/home/chua.jpg";

function Root() {
  const location = useLocation();
  useEffect(() => {
  	sticky();
    goToTop();
  }, []);
  return (
    <div className="App">
      <Header />
      <Outlet />
      <Divider />
      {location.pathname !== '/request-info-2' && <FooterButton />}
      <div className="container">
        <div
          className="col-lg-12 bg-holder"
          style={{
            backgroundImage: `url(${chua})`,
            backgroundSize: 'cover',
            backgroundPosition: 'top center', // Align the top of the image with the top of the container
            height: '40vh', // Adjust the height as needed
            width: '100%',
          }}
        />
      </div>
      <Footer />
      <div id="back-to-top" className="back-to-top">up</div>
    </div>
  );
}

export default Root;
