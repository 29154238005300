import { useEffect } from 'react';
import { searchBox } from './animation';
import logo from '../images/logo/IMG_2214.JPEG';
import '../css/header.css';

function Header() {
  useEffect(() => {
    searchBox();
  }, []);

  return (
    <header className="header header-sticky">
      <div className="topbar bg-dark py-3 d-none d-lg-flex">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 d-flex justify-content-end">
              <a className="btn btn-primary" href="https://canvas.sakyauniversity.com/login/canvas">MySBU</a>
            </div>
          </div>
        </div>
      </div>
      <div className="header-main py-3">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="d-lg-flex align-items-center">
                <a className="navbar-brand logo" href="/">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={logo} alt="Logo" />
                    <div className="ml-2 mt-2 align-items-center flex-column" style={{ display: 'flex' }}>
                      <div className="img-text-primary">SAKYA BUDDHA</div>
                      <div className="img-text">UNIVERSITY</div>
                    </div>
                  </div>
                </a>
                <nav className="navbar navbar-expand-lg">
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                  </button>

                  <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                    <ul className="navbar-nav">
                      <li className="nav-item dropdown active">
                        <a className="nav-link" href="/">Home</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link " href="/academics">Academics</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link " href="/admissions">Admissions & Aid</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link " href="https://library.sakyauniversity.com/">Library</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link " href="/make-a-gift">Make a Gift</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link " href="/contact-us">Contact Us</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link " href="https://bodephatquoc.org/">SBU Vietnamese</a>
                      </li>
                    </ul>
                  </div>

                  {/*<div className="search-icon mr-5 mr-lg-0 d-lg-flex d-none align-items-center">
                    <div className="header-search nav-item">
                      <div className="search">
                        <a className="search-btn not_click"></a>
                        <div className="search-box not-click">
                          <form action="search.html" method="get">
                            <input type="text" className="not-click form-control" name="search" placeholder="Search.." />
                            <a className="search-button" href="#"> <i className="fa fa-search not-click"></i></a>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>*/}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
