import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import './index.css';
import './scss/style.scss';
import Root from './Root';
import ErrorPage from "./ErrorPage";
import Home from './routes/Home';
import Index2 from './routes/Index2';
import AboutUs from './routes/AboutUs';
import Teachers from './routes/Teachers';
import TeachersSingle from './routes/TeachersSingle';
import Gallery from './routes/Gallery';
import Pricing from './routes/Pricing';
import Faq from './routes/Faq';
import ComingSoon from './routes/ComingSoon';
import PrivacyPolicy from './routes/PrivacyPolicy';
import TermsAndConditions from './routes/TermsAndConditions';
import Courses from './routes/Courses';
import CourseDetail from './routes/CourseDetail';
import EventsList from './routes/EventsList';
import EventsGrid from './routes/EventsGrid';
import EventDetail from './routes/EventDetail';
import Blog from './routes/Blog';
import BlogDetail from './routes/BlogDetail';
import ContactUs from './routes/ContactUs';
import Academics from './routes/Academics';
import FacultyDetails from './routes/FacultyDetails';

// Admissions
import Admissions from './admissions/index';
import SBUAdmissions from './admissions/SBUAdmissions';
import AdmissionRequirements from './admissions/AdmissionRequirements';
import Tuition from './admissions/Tuition';
import FinancialAid from './admissions/FinancialAid';

// Start Application
import StartApplication from './application/index';
import ApplicationHome from './application/ApplicationHome';
import ApplicationWelcome from './application/ApplicationWelcome';
import SignIn from './application/SignIn';
import SignUp from './application/SignUp';
import ForgotPassword from './application/ForgotPassword';
import ResetPassword from './application/ResetPassword';
import PersonalBackground from './application/PersonalBackground';
import ProgramSelection from './application/ProgramSelection';
import Affiliations from './application/Affiliations';
import ApplicationFinancialAid from './application/ApplicationFinancialAid';
import AcademicRecord from './application/AcademicRecord';
import Supplemental from './application/Supplemental';
import Signature from './application/Signature';
import Review from './application/Review';
import Status from './application/Status';

import AboutSBU from "./routes/About-SBU";

import reportWebVitals from './reportWebVitals';
import Faculty from "./academics/Faculty";
import MakeaGift from "./routes/MakeaGift";
import RequestInfo from "./routes/requestInfo";
import PlanaVisit from "./routes/PlanaVisit";
import WaysToGive from "./routes/WaysToGive";
import AllEvent from "./routes/AllEvent";
import AllPost from "./routes/AllPost";
import PostDetail from "./routes/PostDetail";
import StudentLife from "./components/StudentLife";
import NewsLetter from "./routes/NewsLetter";
import AcademicsProgram from "./academics/AcademicPrograms";
import {LinksProvider} from "./academics/LinksContext";
import InfoTech from "./routes/InformationTech";
import Campuses from "./routes/Campuses";
import PrivatePolicy from "./routes/PrivatePolicy";
import Accreditation from "./routes/Accreditation";
import RequestInfoAd from "./routes/requestInfoAd";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/index-02",
        element: <Index2 />,
      },
      {
        path: "/about-us",
        element: <AboutUs />,
      },
      {
        path: "/about-SBU",
        element: <AboutSBU />,
      },
      {
        path: "/teachers",
        element: <Teachers />,
      },
      {
        path: "/all-events",
        element: <AllEvent />,
      },
      {
        path: "/all-news",
        element: <AllPost />,
      },
      {
        path: "/make-a-gift",
        element: <MakeaGift />,
      },
      {
        path: "/ways-to-give",
        element: <WaysToGive />,
      },
      {
        path: "/plan-a-visit",
        element: <PlanaVisit />,
      },
      {
        path: "/request-info",
        element: <RequestInfo />,
      },
      {
        path: "/request-info-2",
        element: <RequestInfoAd />,
      },
      {
        path: "/faculty",
        element: <Faculty />,
      },
      {
        path: "/teachers-single",
        element: <TeachersSingle />,
      },
      {
        path: "/gallery",
        element: <Gallery />,
      },
      {
        path: "/pricing",
        element: <Pricing />,
      },
      {
        path: "/faq",
        element: <Faq />,
      },
      {
        path: "/coming-soon",
        element: <ComingSoon />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/terms-and-conditions",
        element: <TermsAndConditions />,
      },
      {
        path: "/course",
        element: <Courses />,
      },
      {
        path: "/course-detail",
        element: <CourseDetail />,
      },
      {
        path: "/events-list",
        element: <EventsList />,
      },
      {
        path: "/events-grid",
        element: <EventsGrid />,
      },
      {
        path: "/event-detail",
        element: <EventDetail />,
      },
      {
        path: "/blog",
        element: <Blog />,
      },
      {
        path: "/news-detail/:id",
        element: <PostDetail />,
      },

      {
        path: "/private-policy",
        element: <PrivatePolicy />,
      },


      {
        path: "/student-life",
        element: <StudentLife />,
      },
      {
        path: "/Accreditation",
        element: <Accreditation />,
      },

      {
        path: "/news-letter",
        element: <NewsLetter />,
      },

      {
        path: "/information-tech",
        element: <InfoTech />,
      },
      {
        path: "/campuses",
        element: <Campuses />,
      },
      {
        path: "/blog-detail",
        element: <BlogDetail />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
      {
        path: "/admissions",
        element: <Admissions />,
        children: [
          {
            path: "",
            element: <SBUAdmissions />,
          },
          {
            path: "requirements",
            element: <AdmissionRequirements />,
          },
          {
            path: "tuition",
            element: <Tuition />,
          },
          {
            path: "financial-aid",
            element: <FinancialAid />,
          },
        ]
      },
      {
        path: "/academics",
        element: <Academics />,
      },
      {
        path: "/academics-program/:programId",
        element: <AcademicsProgram />,
      },
      {
        path: "faculty-details/:id",
        element: <FacultyDetails />,
      },
      {
        path: "/application",
        element: <StartApplication />,
        children: [
          {
            path: "",
            element: <ApplicationHome />,
          },
          {
            path: "welcome",
            element: <ApplicationWelcome />,
          },
          {
            path: "sign-in",
            element: <SignIn />,
          },
          {
            path: "sign-up",
            element: <SignUp />,
          },
          {
            path: "forgot-password",
            element: <ForgotPassword />,
          },
          {
            path: "reset-password",
            element: <ResetPassword />,
          },
          {
            path: "personal-background",
            element: <PersonalBackground />,
          },
          {
            path: "program",
            element: <ProgramSelection />,
          },
          {
            path: "affiliations",
            element: <Affiliations />,
          },
          {
            path: "financial-aid",
            element: <ApplicationFinancialAid />,
          },
          {
            path: "academic",
            element: <AcademicRecord />,
          },
          {
            path: "supplemental",
            element: <Supplemental />,
          },
          {
            path: "signature",
            element: <Signature />,
          },
          {
            path: "review",
            element: <Review />,
          },
          {
            path: "status",
            element: <Status />,
          },
        ],
      }
    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <LinksProvider> {/* Wrap RouterProvider with LinksProvider */}
        <PayPalScriptProvider options={{
          clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
          currency: 'USD',
          intent: 'capture',
        }}>
          <RouterProvider router={router} />
        </PayPalScriptProvider>
      </LinksProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
