import React, {useEffect} from "react";
import sbuHandbook from './SBU_Handbook_2024.pdf';

function Handbook() {
    useEffect(() => {
        // Open the handbook PDF in a new tab when the component mounts
        window.open(sbuHandbook, "_blank");
    }, []); // Empty dependency array ensures this effect runs only once after initial render

    return (
      <div>
          <p><a href={sbuHandbook} target="_blank">Sakya Buddha University Handbook</a></p>
      </div>
    );
}

export default Handbook;
