import axios from 'axios';
import {useState} from "react";
import InnerHeader from "../components/InnerHeader";
import imgBg from "../images/bg/request-info-bg.png";
import Divider from "../components/divider";

function RequestInfoAd() {
    const [emailMsg, setEmailMsg] = useState("");

    function handleRequestInfo(event) {
        event.preventDefault();
        const fd = new FormData(event.target)
        const data = Object.fromEntries(fd.entries());
        console.log("btn request info is clicked")
        console.log(data);
        axios.post(
            `${process.env.REACT_APP_BACKEND_API}/notifications/send_mail`, data
        )
            .then(response => {
                if (response.status === 200) {
                    setEmailMsg("Request Information email have been successfully sent")
                } else {
                    setEmailMsg("Request Information email have not been sent")
                }
            })
            .catch(error => {
                console.log('api errors:', error);
                setEmailMsg("Request Information email have not been sent")
            })
    }

    return (
        <>
            <InnerHeader backgroundImage={imgBg}/>
            <Divider />

            <form onSubmit={handleRequestInfo}>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <div style={{marginLeft: '30%'}} className="section-title textAlign: 'left'}}">
                                    <h4> YOU ARE READY.</h4>
                                    <p>
                                        This is the intersection of hands-on learning and scholarly depth.<br/>
                                        Where you push the boundaries of your intellect and unearth your
                                        capabilities.<br/>
                                        Where you unearth the work that ignites your passion—and employ it to reshape
                                        our
                                        world.<br/>
                                        Please fill in the "*" marked fields. <br/>
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-5 col-md-8 col-sm-10 mb-2">
                                <div className="login bg-white shadow p-5">
                                    <div className="form-group">
                                        <label htmlFor="firstName">First Name / Tên *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="firstName"
                                            name="firstName"
                                            required
                                        />
                                        <span className="focus-border"></span>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="lastName">Last Name / Họ *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="lastName"
                                            name="lastName"
                                            required
                                        />
                                        <span className="focus-border"></span>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="street">Street / Địa chỉ</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="street"
                                            name="street"
                                        />
                                        <span className="focus-border"></span>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="city">City / Thành phố </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="city"
                                            name="city"
                                        />
                                        <span className="focus-border"></span>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="state">State / Tiểu bang</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="state"
                                            name="state"
                                        />
                                        <span className="focus-border"></span>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="country">Country / Quốc gia</label>
                                        <select id="country" name="country" className="form-control">
                                            <option value="">Select Country</option>
                                            <option value="USA">USA</option>
                                            <option value="Canada">Canada</option>
                                            {/* Add more countries as needed */}
                                        </select>
                                        <span className="focus-border"></span>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="zip">Zip / Mã bưu chính</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="zip"
                                            name="zip"
                                        />
                                        <span className="focus-border"></span>
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="email">Email *</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            required
                                        />
                                        <span className="focus-border"></span>
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="mobilePhone">Mobile Phone / Số điện thoại</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="mobilePhone"
                                            name="mobilePhone"
                                            aria-label="Mobile Phone"
                                        />
                                        <span className="focus-border"></span>
                                    </div>

                                    <div className="form-group">
                                        <label>Interested In / Quan tâm đến *</label>
                                        <select name="interestIn" className="form-control" required>
                                            <option value="">Select Interest</option>
                                            <option value="Arts">Arts</option>
                                        </select>
                                        <span className="focus-border"></span>
                                    </div>
                                    <div className="form-group">
                                        <label>Graduate Academics for Prospective Inquiry</label>
                                        <select name="graduateProgram" className="form-control">
                                            <option value="">Select Program</option>
                                            <option value="BP">Master of Art in Buddhist Philosophy / Thạc sĩ Triết học Phật giáo</option>
                                        </select>
                                        <span className="focus-border"></span>
                                    </div>


                                    <button className="btn btn-primary">Submit</button>
                                    <div className="mt-3">{emailMsg}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
        ;
}

export default RequestInfoAd;
