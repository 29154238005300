import sbuHandbook from './SBU_Handbook_2024.pdf'
import {Link} from "react-router-dom";
import React from "react";

function ReligiousStudies() {
    return (
        <>
            <div className="my-sm-3 text-justify">
                <h5 className="mb-4">Religious Studies</h5>
                <p>The Department of Religious Studies at SBU specializes in exploring religion with a focus on
                    real-life applications. Emphasizing Asian religious traditions, particularly Buddhism as a wisdom
                    and freedom-oriented faith, the department is dedicated to creating an inclusive academic
                    environment that encourages open inquiry into religious diversity. It actively engages enrolled
                    students in acquiring a profound understanding of the historical and cultural influences of
                    religious practices.</p>

                <p>The Religious Studies program at SBU offers an immersive and interdisciplinary exploration of
                    the varied global religious traditions, covering beliefs and practices, and their cultural, historical,
                    and social contexts. Through intensive academic study, the program engages in critical analysis,
                    research methodologies, and philosophical inquiry related to religion, aiming to cultivate a deep
                    understanding of religious phenomena and their impact on society. Students are not only
                    encouraged to become scholars of Buddhist studies but to also embrace transformative and
                    healing practices for personal growth.</p>

                <p>Students will explore theology, ethics, spirituality, comparative religion, and the intersection of
                    faith with politics, art, and contemporary issues. The program encourages the development of
                    nuanced perspectives, cultivating tolerance, empathy, and critical thinking. Graduates are well-
                    prepared for careers in academia, interfaith dialogue, social services, and fields where an
                    understanding of diverse cultures and beliefs is valued.</p>

                <p>We extend a warm welcome to students from all religious backgrounds, encouraging them to
                    contribute their distinctive perspectives to the exploration of religion.</p>


                <div>
                    <Link to={`/academics-program/3`} className="btn btn-primary mb-3  text-left" style={{ width: '60%'}}>
                        Master of Arts in Buddhist Philosophy
                    </Link>
                </div>
                {/*<div>*/}
                {/*    <Link to={`/academics-program/4`} className="btn btn-primary mb-3  text-left" style={{ width: '60%'}}>*/}
                {/*        Master of Arts in Buddhist Psychology*/}
                {/*    </Link>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <Link to={`/academics-program/5`} className="btn btn-primary mb-3  text-left" style={{ width: '60%'}}>*/}
                {/*        Master of Arts in History of Buddhism*/}
                {/*    </Link>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <Link to={`/academics-program/6`} className="btn btn-primary mb-3  text-left" style={{ width: '60%'}}>*/}
                {/*        Master of Arts in Literature of Language*/}
                {/*    </Link>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <Link to={`/academics-program/7`} className="btn btn-primary mb-3 text-left" style={{ width: '60%'}}>*/}
                {/*        Master of Arts in Vietnamese Buddhist Studies*/}
                {/*    </Link>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <Link to={`/academics-program/1`} className="btn btn-primary mb-3  text-left" style={{ width: '60%'}}>*/}
                {/*        Doctor of Buddhist Studies*/}
                {/*    </Link>*/}
                {/*</div>*/}


            </div>
        </>
    );
}

export default ReligiousStudies;